import * as React from 'react';

export const Gv = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={536}
    height={332}
    viewBox="0 0 536 332"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.258 332C74.436 332 0 257.68 0 165.999 0 74.317 74.436 0 166.258 0c11.958 0 23.616 1.284 34.859 3.679l37.1 73.7c-19.262-12.9-42.33-20.864-67.492-21.733a124.093 124.093 0 00-4.593-.086v.015c-61.058.068-110.535 49.759-110.535 110.74 0 61.023 49.545 110.815 110.661 110.815 51.655 0 95.04-37.867 107.236-82.478h-118v-57.356h113.397l48.665 97.123C291.344 291.708 233.46 332 166.258 332zM371.326 331.066l-32.283-63.981L471.014 3.463h64.87L371.326 331.066z"
      fill="#222"
    />
  </svg>
);
