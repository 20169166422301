import * as React from 'react';

export const HeadwayMarkWhite = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={103}
    height={78}
    viewBox="0 0 103 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#headway-mark-white_svg__clip0)">
      <path
        d="M102.7 51.36A51.348 51.348 0 0038.045 1.754 51.35 51.35 0 006.9 77.06h88.87a51 51 0 006.93-25.7z"
        fill="#EEE"
      />
    </g>
    <defs>
      <clipPath id="headway-mark-white_svg__clip0">
        <path fill="#fff" d="M0 0h103v78H0z" />
      </clipPath>
    </defs>
  </svg>
);
