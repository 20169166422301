import * as React from 'react';

export const HeadwayLogoHelix = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={125}
    height={24}
    viewBox="0 0 125 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#HeadwayLogoHelix_svg__clip0_109_5091)">
      <path
        d="M25.395 12.7a12.698 12.698 0 10-23.689 6.355h21.976a12.61 12.61 0 001.713-6.355z"
        fill="#13AA65"
      />
      <path
        d="M36.866 5.059h2.802v5.704h6.167V5.06h2.799v14.094h-2.8v-5.732h-6.166v5.75h-2.802V5.059zM59.927 15.135h-7.032c.22 1.39 1.483 1.832 2.579 1.832a3.313 3.313 0 002.779-1.35l1.674 1.652a5.61 5.61 0 01-4.473 2.117c-2.7 0-5.361-1.714-5.361-5.28 0-3.526 2.599-5.36 5.193-5.36 2.472 0 4.718 1.693 4.718 5.098-.001.432-.027.863-.077 1.291zm-4.735-4.073a2.203 2.203 0 00-2.297 1.996h4.512c-.12-1.29-1.006-1.996-2.215-1.996zM81.541 10.377V5.06h2.72v14.094h-2.72v-1.248a3.931 3.931 0 01-3.214 1.483c-2.58 0-5.138-1.793-5.138-5.341s2.497-5.3 5.017-5.3c1.49-.002 2.68.582 3.335 1.63zm-.022 3.69c0-2.226-1.469-3.024-2.819-3.024-1.572 0-2.762 1.068-2.762 3.002 0 1.874 1.15 3.043 2.802 3.043 1.5 0 2.77-.986 2.77-3.021h.01zM97.268 15.214l1.993-6.206h3.052L98.537 19.17h-2.6l-2.225-5.935-2.196 5.935h-2.591L85.196 9.008h3.022l1.993 6.182 2.178-6.182h2.659l2.22 6.206zM125.002 18.782c0 3.324-2.621 5.097-5.321 5.097-2.297 0-4.414-1.269-5.079-3.687l2.46-.888c.342 1.553 1.484 2.238 2.72 2.238 1.43 0 2.518-.846 2.518-2.56V17.5a3.415 3.415 0 01-2.859 1.55c-2.116 0-4.132-1.367-4.132-4.129V8.985h2.74v5.562c0 1.29.665 2.178 1.978 2.178 1.39 0 2.226-1.068 2.226-2.762V9.008h2.742l.007 9.774zM69.163 9.008v1.236c-.626-1.026-1.714-1.484-3.024-1.484-2.6 0-5.26 1.835-5.26 5.381 0 3.385 2.458 5.26 4.997 5.26a4.217 4.217 0 003.287-1.467v1.236h2.72V9.008h-2.72zm-2.903 8.019c-1.432 0-2.66-1.128-2.66-2.968 0-1.874 1.268-3.004 2.72-3.004 1.45 0 2.761 1.15 2.761 2.967 0 1.857-1.35 3.005-2.821 3.005zM110.44 9.008v1.236c-.625-1.026-1.716-1.484-3.024-1.484-2.599 0-5.259 1.835-5.259 5.381 0 3.385 2.458 5.26 4.997 5.26a4.221 4.221 0 003.286-1.467v1.236h2.72V9.008h-2.72zm-2.903 8.019c-1.431 0-2.66-1.128-2.66-2.968 0-1.874 1.268-3.004 2.72-3.004 1.451 0 2.762 1.15 2.762 2.967 0 1.857-1.35 3.005-2.822 3.005z"
        fill="#4D4C4D"
      />
    </g>
    <defs>
      <clipPath id="HeadwayLogoHelix_svg__clip0_109_5091">
        <path fill="#fff" d="M0 0h125v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
