import * as React from 'react';

export const MarkerSelected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={140}
    height={201}
    viewBox="0 0 140 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M73.502 201C103.05 201 127 193.389 127 184s-23.951-17-53.502-17C43.95 167 20 174.611 20 184s23.952 17 53.498 17h.004z"
      fill="#152234"
      fillOpacity={0.3}
    />
    <path
      d="M70 197.74a284.82 284.82 0 01-1.871-2.152 424.01 424.01 0 01-7.902-9.463c-6.543-8.062-15.265-19.354-23.984-32.276-8.721-12.927-17.421-27.458-23.937-42.001C5.782 97.285 1.5 82.822 1.5 70 1.5 32.128 32.128 1.5 70 1.5s68.5 30.628 68.5 68.5c0 12.822-4.282 27.285-10.806 41.848-6.516 14.543-15.216 29.074-23.937 42.001-8.72 12.922-17.441 24.214-23.984 32.276a424.01 424.01 0 01-7.902 9.463c-.76.882-1.39 1.604-1.871 2.152zM43.5 70c0 14.628 11.872 26.5 26.5 26.5S96.5 84.628 96.5 70 84.628 43.5 70 43.5 43.5 55.372 43.5 70z"
      fill="#13AA65"
      stroke="#8C98A2"
      strokeWidth={3}
    />
    <path
      d="M116.191 49.212a51.454 51.454 0 015.309 22.775h.5-.5a51.099 51.099 0 01-6.806 25.513H25.277a51.46 51.46 0 0110.75-64.211 51.508 51.508 0 0144.011-11.802 51.508 51.508 0 0136.153 27.726z"
      fill="#fff"
      stroke="#13AA65"
    />
  </svg>
);
