import * as React from 'react';

export const CloseButton = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={42}
    viewBox="0 0 42 42"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="#4D4D4D" strokeWidth={0.9} fill="none" fillRule="evenodd">
      <path d="M13.39 13.39l15.107 15.107M28.497 13.39L13.39 28.497M40.794 21.12c0-10.868-8.808-19.676-19.675-19.676-10.866 0-19.675 8.808-19.675 19.675s8.81 19.675 19.675 19.675c10.867 0 19.675-8.808 19.675-19.675z" />
    </g>
  </svg>
);
