import * as React from 'react';

export const Aetna = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 36.7" {...props}>
    <path
      d="M175.8 27.9c0-2.5.3-6.1.3-10.3 0-7.8-3.2-10.7-11.9-10.7-4.2 0-7.9.6-10.2 1.6l.3 6.2c2-1.1 4.9-2.1 7.7-2.1 4 0 5.1 1.6 5.1 5.5v.6h-1.3c-9.8 0-14.4 3-14.4 8.5 0 5.7 2.8 9 10.9 9 4.3 0 8.4-.6 13.9-.6-.4-2.1-.4-5.4-.4-7.7zm-11.4 3c-3 0-4.3-1-4.3-3.5 0-2.4 1.6-3.8 5.7-3.8h1.2c0 2.3-.1 5.1.1 7-.7.2-1.7.3-2.7.3zm-75.6-.3c-4.9 0-7.5-2.5-7.5-6.5v-.2c.9 0 2.6.1 3.6.1 8.7 0 13.1-2.7 13.1-8.6C98 10.3 94.3 7 86.4 7 76.7 7 72.9 11.3 72 19c-1.4-.1-3-.1-5.1-.1v-1.3c0-7.8-3.2-10.7-11.9-10.7-4.2 0-7.9.6-10.2 1.6l.3 6.2c2-1.1 4.9-2.1 7.7-2.1 4 0 5.2 1.6 5.2 5.5v.6h-1.3c-9.8 0-14.4 3-14.4 8.5 0 5.7 2.8 9 10.9 9 4.3 0 8.4-.6 13.9-.6-.4-2.3-.4-5.6-.4-7.8 0-1.2.1-2.6.1-4.2 2.3 0 4 .1 5.1.1.8 9.2 5.6 12.5 15.4 12.5 3.6 0 7.3-.6 9.3-1.5l-.3-6.2c-1.9 1.2-4.6 2.1-7.5 2.1zm-33.5.3c-3 0-4.3-1-4.3-3.5 0-2.4 1.6-3.8 5.7-3.8h1.2c0 2.3-.1 5.1.1 7-.7.2-1.8.3-2.7.3zM81.2 19c0-3.9 1.2-6.8 4.8-6.8 2 0 3.4 1.1 3.4 3 0 2.7-1.8 4-5.6 4-.9 0-1.9 0-2.6-.1V19zm66.6 3.7v-5.5c0-7.1-3.1-10.2-11.9-10.2-4.9 0-8.4.9-13.9.9.3 5 .3 11.1.3 14.9 0 3.9-.1 10.8-.3 13h9.4c-.2-2.2-.4-8.7-.4-13 0-2.5 0-5.5.1-9.5.9-.3 2.1-.6 3.4-.6 3.3 0 4.4 1.5 4.4 5.8v4.2c0 3.9-.1 10.8-.3 13h9.4c0-2.2-.2-8.6-.2-13zm-29.6 6.8l.4 6.2c-.7.2-3 .6-6 .6-6.2 0-9.5-2.6-9.5-9 0-5 .1-10.5.1-13.5H100c.1-1.4 0-3.3 0-4.7 4.8-.8 6.3-3.5 7.1-8.8h5.3c-.2 2.2-.3 5.3-.4 7.5h5.6v6h-5.7v10.9c0 4.2.9 5.4 3.7 5.4 1 0 2.1-.2 2.6-.6zM11.2 4.4c1.2 0 2.3.4 3.2 1.3l5.2 5.2 5.2-5.2c.9-.9 2-1.3 3.2-1.3 1.1 0 2.3.4 3.2 1.3l6.4 6.4c1.8 1.8 1.8 4.6 0 6.4l-18 17.9-18-18c-1.8-1.8-1.8-4.6 0-6.4L8 5.6c.9-.8 2-1.2 3.2-1.2M177.6 7h4.9v1h-1.9v5h-1.2V8h-1.9l.1-1zm6 0h1.6l.8 2.6c.2.8.6 2.3.6 2.3s.4-1.5.6-2.3L188 7h1.6v6h-1.1v-2.2c0-1.1.1-2.7.1-2.7s-.4 1.4-.7 2.6l-.7 2.3h-1.1l-.7-2.3c-.4-1.2-.7-2.6-.7-2.6s.1 1.7.1 2.7V13h-1.1V7h-.1z"
      fill="#7d3f98"
    />
  </svg>
);
