import * as React from 'react';

export const Oscar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    height={559}
    width={2500}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 228.386"
    {...props}
  >
    <path
      d="M966.886 25.46l-2.343-.06V6.08h-40.231v215.934h46.833V101.51c0-19.584 2.624-41.963 22.47-51.758 8.1-4.305 21.062-7.835 30.385-7.835V0c-22.688.789-43.347 6.662-57.114 25.46zM777.312 191.172c-42.937 0-76.555-35.652-76.555-76.386 0-42.313 32.407-77.573 76.959-77.573 45.772 0 77.775 34.088 77.775 77.573 0 41.907-34.434 76.386-78.18 76.386zM852.52 34.097C835.637 11.008 806.734 0 777.716 0 709.668 0 658.63 49.359 658.63 115.176c0 65.418 51.85 113.21 119.086 113.21 29.758 0 58.646-12.079 74.804-36.58v30.208h45.956V6.079H852.52zm-315.816 3.117c29.976 0 50.632 15.277 63.188 40.742h46.583C633.915 31.34 584.5 0 536.297 0 469.867 0 420.86 50.535 420.86 113.998c0 63.465 48.2 114.388 115.032 114.388 51.445 0 93.976-30.557 110.584-77.17h-46.987c-11.747 25.068-34.836 39.957-63.597 39.957-44.959 0-72.907-36.042-72.907-77.175 0-42.696 27.54-76.784 73.72-76.784zM337.007 88.538c-15.393-4.699-34.832-9.014-34.832-28.21 0-14.494 12.149-23.114 26.325-23.114 17.014 0 26.734 10.578 28.76 26.249h42.127C398.575 25.857 369.007 0 330.524 0c-36.858 0-70.478 25.46-70.478 62.675 0 76.787 102.479 53.284 102.479 98.722 0 18.802-13.773 29.776-32.404 29.776-25.113 0-31.209-17.186-32.833-39.51h-46.244c0 34.077 16.293 60.271 49.507 71.63 9.319 3.14 19.038 5.093 28.761 5.093 42.934 0 75.341-26.637 75.341-69.333 0-41.923-30.785-59.55-67.646-70.515zM121.11 191.173c-43.746 0-78.986-34.867-78.986-77.175 0-42.307 35.24-76.784 78.986-76.784 43.748 0 78.986 34.088 78.986 76.394 0 42.305-34.836 77.565-78.986 77.565zM121.11 0C56.299 0 0 48.969 0 112.819c0 64.251 55.087 115.567 121.11 115.567 68.052 0 121.115-52.887 121.115-114.778C242.225 49.358 186.73 0 121.11 0z"
      fill="#2854a1"
    />
  </svg>
);
