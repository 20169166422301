import * as React from 'react';

export const Accel = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={828}
    height={260}
    viewBox="0 0 828 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#accel_svg__clip0)" fill="#222">
      <path d="M639.023 214.986a63.05 63.05 0 01-12.155-21.45l-.704-1.983-.705-2.422-.44-1.498-.705-2.774-.352-1.63a76.6 76.6 0 01-.925-4.801 15.19 15.19 0 00-.264-1.762l-.353-2.863c.07-.629.07-1.264 0-1.894l-.22-2.422 123.313-29.731a52.272 52.272 0 00-5.593-18.279 55.148 55.148 0 00-21.36-24.049 86.093 86.093 0 00-34.131-12.508l-2.07-.309a100.221 100.221 0 00-22.02 0 91.246 91.246 0 00-52.012 23.609 86.331 86.331 0 00-26.952 49.066v.749a46.193 46.193 0 00-.529 3.788c-.132 1.365 0 1.541-.264 2.422.057.674.057 1.352 0 2.026a23.823 23.823 0 010 3.524v5.682a83.706 83.706 0 006.342 29.687A90.776 90.776 0 00669.455 260a92.466 92.466 0 0042.939-9.602 89.358 89.358 0 0033.119-28.145l-2.114-5.329c-8.72 16.649-68.879 37.35-104.376-1.938zm56.108-73.16l-73.327 17.618c0-2.202 0-4.404.308-6.474v-.661a20.144 20.144 0 010-2.114l.352-2.863a86.71 86.71 0 013.083-13.522 78.79 78.79 0 0111.274-22.904 56.34 56.34 0 0117.132-15.856 41.044 41.044 0 0121.404-5.814c10.511 0 18.203 2.936 23.077 8.81a29.335 29.335 0 014.404 6.782c4.888 12.465 4.536 33.21-7.707 36.998zM418.954 216.791c-8.808 16.826-68.878 37.483-104.375-1.761a64.59 64.59 0 01-12.772-23.256 95.128 95.128 0 01-4.404-29.995 92.547 92.547 0 014.14-28.013 78.79 78.79 0 0111.274-22.904 56.555 56.555 0 0116.824-15.812 41.086 41.086 0 0121.403-5.814c10.511 0 18.204 2.936 23.078 8.81a34.006 34.006 0 017.31 22.374 36.153 36.153 0 01-1.85 12.377l37.435-8.809c-13.609-35.237-58.706-40.126-67.03-40.126-71.433-1.145-110.629 66.069-87.552 120.817a90.938 90.938 0 0082.576 54.925 92.491 92.491 0 0042.939-9.602 89.33 89.33 0 0033.118-28.145l-2.114-5.066z" />
      <path d="M581.375 216.791c-8.808 16.826-68.879 37.483-104.375-1.761a64.261 64.261 0 01-12.64-23.08 95.157 95.157 0 01-4.404-29.995 92.485 92.485 0 014.14-28.013 78.707 78.707 0 0111.23-22.904 56.954 56.954 0 0116.823-15.988 41.043 41.043 0 0121.404-5.814c10.482 0 18.174 2.936 23.077 8.81a34.008 34.008 0 017.443 22.374 35.255 35.255 0 01-1.894 12.377l37.214-8.809c-13.52-35.237-58.617-40.082-66.853-40.082-71.433-1.145-110.629 66.069-87.552 120.817a90.86 90.86 0 0082.532 55.145 92.47 92.47 0 0043.027-9.602 89.364 89.364 0 0033.118-28.145l-2.29-5.33zM799.506 224.632V0l-52.848 12.861h-.353v4.977c1.85 0 17.837 4.405 17.837 15.416V223.84c0 24.049-17 27.528-28.494 28.277v4.008H828v-4.008c-11.45-.749-28.098-4.273-28.494-27.484zM160.703 0l-52.32 12.817h-.396v3.876c1.849 0 17.395 5.506 13.212 15.416-19.863 43.957-84.293 190.673-87.684 197.279C22.372 251.411 6.562 251.411.044 252.116v4.008h81.915v-4.096s-31.093.352-31.093-15.901c0-9.866 16.075-48.67 20.435-59.285l106.71-26.031c.264.661 28.978 70.209 28.978 84.876 0 15.856-25.499 16.341-26.424 16.341v4.008H287.45v-4.008a38.754 38.754 0 01-36.024-22.904C248.211 222.782 160.703 0 160.703 0zm13.608 141.826l-98.518 24.049c.529-1.277 54.126-126.895 55.623-130.066 2.246 5.241 42.851 106.017 42.851 106.017h.044z" />
    </g>
    <defs>
      <clipPath id="accel_svg__clip0">
        <path fill="#fff" transform="rotate(90 414 414)" d="M0 0h260v828H0z" />
      </clipPath>
    </defs>
  </svg>
);
