import * as React from 'react';

export const Thrive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={233}
    height={30}
    viewBox="0 0 233 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.577 23.162c0 3.65-2.943 6.838-6.712 6.838-3.237 0-6.965-1.576-6.965-6.96V6.797c0-3.859 2.777-6.797 6.876-6.797 3.901 0 6.801 2.938 6.801 7.042v1.66h-4.228V7.29c0-1.656-.992-3.066-2.609-3.066-2.116 0-2.614 1.449-2.614 3.441v15.086c0 1.697.662 3.02 2.57 3.02 1.16 0 2.653-.656 2.653-2.982v-1.448h4.228v1.821zM153.864.248h3.527l6.587 29.507h-4.226l-1.242-6.345h-5.762l-1.244 6.345h-4.226L153.864.248zm1.787 8.496h-.087l-2.07 10.69h4.229l-2.072-10.69zM166.093.248h6.342c2.323 0 4.022.542 5.474 2.072 1.448 1.53 1.906 3.396 1.906 6.917 0 2.568-.21 4.313-1.204 5.928-1.16 1.906-3.028 3.069-6.135 3.069h-2.151v11.521h-4.232V.248zm4.232 14.005h2.031c3.437 0 3.483-2.027 3.483-4.97 0-2.78 0-5.06-3.316-5.06h-2.198v10.03zM182.258 29.752h4.228V.248h-4.228v29.504zM193.947 4.224h-4.888V.248h14.008v3.976h-4.891v25.531h-4.229V4.224zM207.331.248h3.521l6.59 29.507h-4.224l-1.244-6.345h-5.76l-1.244 6.345h-4.224L207.331.248zm1.781 8.496h-.082l-2.07 10.69h4.224l-2.072-10.69zM219.864.248h4.229v25.278h8.368v4.229h-12.597V.248z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.082 29.74l15.595-9.002v-6.695L17.082 23.05v6.69zM17.082 9.377v6.692l15.595-9.006V.37L17.082 9.377zM0 13.9l15.6 9.004v-6.692L0 7.211V13.9z"
      fill="#A02629"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.483 13.425h2.399c2.94 0 3.396-1.658 3.396-4.6 0-2.984-.5-4.596-3.605-4.596h-2.19v9.196zM70.253.253h6.794c5.013 0 7.454 2.94 7.454 8.652 0 3.398-.953 5.882-3.64 7.377l4.554 13.457H80.94l-3.936-12.586h-2.521v12.586h-4.23V.253zM37.162 4.229h4.888v25.51h4.223V4.23h4.89V.253h-14v3.976zM63.317 13.522h-5.218V.253h-4.222v29.486h4.222V17.25h5.218v12.49h4.222V.252h-4.222v13.269zM87.47 29.74h4.228V.252H87.47v29.486zM101.938 20.837h-.083L98.497.253h-4.471l6.004 29.486h3.726L109.8.253h-4.469l-3.393 20.584zM124.961 4.229V.253h-12.587v29.486h12.587v-4.22h-8.363V17.5h7.287v-3.978h-7.287V4.229h8.363z"
      fill="#000"
    />
  </svg>
);
